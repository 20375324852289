
















































import AddClientLocation from '../components/forms/AddClientLocation.vue';
import Component from 'vue-class-component';
import UsersViewAllLayout from '../../shared/layouts/UsersViewAllLayout.vue';
import Vue from 'vue';
import { Client, Department, Position, Shift, Site } from '@/models';
import { Watch } from 'vue-property-decorator';
import { routeNames } from '@/router';

@Component({
  name: 'ClientsIndexPage',
  components: {
    UsersViewAllLayout,
    AddClientLocation,
  },
})
export default class extends Vue {
  clients: Client[] = [];

  client: Client | null = null;
  site: Site | null = null;
  department: Department | null = null;
  shift: Shift | null = null;
  position: Position | null = null;

  sites: Site[] = [];
  departments: Department[] = [];
  shifts: Shift[] = [];
  positions: Position[] = [];

  adding = false;

  headers = [
    { text: 'Client', value: 'data.name' },
    // { text: 'Actions', value: 'actions', width: '1%', align: 'center', sortable: false },
  ];

  total = 0;
  loading = false;

  options = {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [false],
  };

  routeNames = routeNames;

  async loadClients(): Promise<void> {
    this.loading = true;

    let { data, meta } = await Client.paginate(this.options.page, this.options.itemsPerPage);

    this.clients = data;
    this.total = meta.total;

    this.loading = false;
  }

  finishAdding(): void {
    this.adding = false;
    this.loadClients();
  }

  viewClient(client: Client): void {
    this.$router.push({
      name: routeNames.practitioner.clients.view,
      params: { uuid: client.uuid },
    });
  }

  @Watch('options', { deep: true })
  onOptionsChanged(): void {
    this.loadClients();
  }
}
